<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        <span class="mr-3">編輯收支明細表</span>
        {{ editItem.BYear }}年度 第{{ nowSemester == 1 ? "一" : "二" }}學期
        培育學校:
        {{ editItem.school }}
      </h5>

      <div class="dtc-search1">
        <b-input-group prepend="結報日期">
          <calendarInput
            ref="currentTime"
            v-model="currentTime"
            @input="setTime"
          />
        </b-input-group>
        <!-- <b-button variant="warning" @click="saveAllData">儲存</b-button> -->
        <b-button variant="success" @click="goBackLastPage">返回</b-button>
        <b-button variant="danger" @click="openPrintPage">列印</b-button>
      </div>
    </section>
    <header class="dtc-grid-header ml-1 my-dark">
      <div>序</div>

      <div>培育學校</div>
      <div>年度</div>
      <div>學期</div>
      <div class="header-column4">
        <div class="header-column4-row1">
          <div class="header-column4-row1-word">核撥</div>
        </div>
        <div class="header-column4-row2">
          <div>日期</div>
          <div>金額</div>
        </div>
      </div>
      <div class="header-column5">
        <div class="header-column5-row1">
          <div class="header-column5-row1-word">結報</div>
        </div>
        <div class="header-column5-row2">
          <div>業務費</div>
          <div>設備費</div>
          <div>小計</div>
        </div>
      </div>
      <div>餘(絀)數</div>
      <div class="header-column6">
        <div class="header-column6-row1">
          <div class="header-column6-row1-word">
            繳回款
          </div>
        </div>
        <div class="header-column6-row2">
          <div>經常門</div>
          <div>資本門</div>
        </div>
      </div>
      <div>其他衍生收入</div>
    </header>

    <!-- <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
      <div
        style="grid-column: 1 / -1;padding: 20px 0px;font-size:24px;color:#000;"
      >
        暫無資料
      </div>
    </main> -->
    <main
      class="dtc-grid-main main-dtc-body ml-1"
      v-for="(item, i) in items"
      :key="i"
      style="color:#dee2e5;"
      :style="
        i % 2 == 0 ? `background-color: #fff;` : `background-color: #efefef;`
      "
    >
      <div>{{ i + 1 }}</div>
      <div :title="item.school">{{ item.school || "" }}</div>
      <div :title="item.BYear">{{ item.BYear || "" }}</div>
      <div :title="item.Term">{{ item.Term == 1 ? "一" : "二" }}</div>
      <div :title="item.ApproveDate">
        {{ item.ApproveDate ? $twDate(item.ApproveDate) : "暫無資料" }}
      </div>
      <div :title="item.Approve_Amt" class="price-content">
        {{ item.Approve_Amt ? $formatPrice(item.Approve_Amt) : "0" }}
      </div>


      <div :title="item.Fee_3" class="price-content">
        {{ item.Fee_3 ? $formatPrice(item.Fee_3) : "0" }}
      </div>
      <div :title="item.Fee_2" class="price-content">
        {{ item.Fee_2 ? $formatPrice(item.Fee_2) : "0" }}
      </div>
      <div :title="item.SubTotal" class="price-content">
        {{ item.SubTotal ? $formatPrice(item.SubTotal) : "0" }}
      </div>
      <div :title="item.Surplus" class="price-content">
        {{ item.Surplus ? $formatPrice(item.Surplus) : "0" }}
      </div>
      <div :title="item.Often" class="price-content">
        {{ item.Often ? $formatPrice(item.Often) : "0" }}
      </div>
      <div :title="item.Capital" class="price-content">
        {{ item.Capital ? $formatPrice(item.Capital) : "0" }}
      </div>
      <div :title="item.Other_Income" class="price-content">
        {{ item.Other_Income ? $formatPrice(item.Other_Income) : "0" }}
      </div>
    </main>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import moment from "moment";
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import calendarInput from "@/components/localCalendar.vue";
export default {
  name: "ShowTable",
  data() {
    return {
      currentTime: new Date("2020-05-30"),
      isSuccessSave: 0,

      items: [],
      headers: [],
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      years,
      editItem: {},
      seqNumber: "",
    };
  },
  components: { calendarInput },
  props: [],
  computed: {
    nowTab() {
      return store.nowTab;
    },

    nowSemester() {
      return store.semester;
    },
  },
  methods: {
    async setTime(time) {
      const url = `BalanseSheet/SetCheckoutDate`;
      const saveTime = moment(time).format("YYYY-MM-DD");
      const obj = {
        SeqNo: this.seqNumber,
        Checkout_Date: saveTime,
      };
      try {
        await window.axios.put(url, obj);
        this.getData();
        this.$bvToast.toast(`結報日期儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.isSuccessSave += 1;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      this.seqNumber = "";
      const url = `BalanseSheet/Get?schoolcode=${this.editItem.SchoolCode}&byear=${this.editItem.BYear}&term=${this.editItem.Term}&category=S`;

      try {
        const res = await window.axios.get(url);
        this.seqNumber = res.SeqNo;

        this.currentTime = new Date(res.Checkout_Date);
        this.$refs.currentTime.chYears = res.Checkout_Date;

        const obj = { ...res, ...store.editItem };
        this.items = [obj];
        console.log(this.items);//wawa
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async goBackLastPage() {
      if (this.isSuccessSave > 0) {
        this.$router.push("/localbudgetmanagetab");
        return;
      }
      let result = await Swal.fire({
        title: "",
        text: "您確定放棄編輯結報日期並返回上一頁",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        this.$router.push("/localbudgetmanagetab");
      }
    },
    // async saveAllData() {
    //   this.$bvToast.toast(`儲存成功`, {
    //     title: "系統資訊",
    //     autoHideDelay: 5000,
    //     variant: "success",
    //   });
    // },
    openPrintPage() {
      let url = "";
      url = `/#/doc10-1?schoolcode=${this.editItem.SchoolCode}&byear=${this.editItem.BYear}&term=${this.nowSemester}&category=S`;
      window.open(url);
    },
  },
  mounted() {
    this.editItem = { ...store.editItem };
    this.isSuccessSave = 0;
    this.getData();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 40px 150px 80px 70px 360px 600px 140px 300px 160px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #dee2e5;
  border-bottom: 0px;
  margin-right: 4px;
  color: #0757a7;
  > div {
    border-right: 1px solid #dee2e5;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding: 4px 0;
    height: 65px;
    padding-top: 25px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column4 {
    padding-top: 0px;

    .header-column4-row1 {
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    .header-column4-row2 {
      display: grid;
      grid-template-columns: 210px 150px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
  }
  .header-column5 {
    padding-top: 0px;

    .header-column5-row1 {
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    .header-column5-row2 {
      display: grid;
      grid-template-columns: repeat(4, 200px);
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
  }
  .header-column6 {
    padding-top: 0px;
    .header-column6-row1 {
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    .header-column6-row2 {
      display: grid;
      grid-template-columns: repeat(2, 150px);
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer,
.dtc-grid-footer2 {
  display: grid;
  grid-template-columns:
    40px 150px 80px 70px 210px 150px repeat(3, 200px) 140px repeat(2, 150px)
    160px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  // border-left: 1px solid #dee2e5;
  // border-right: 1px solid #dee2e5;
  // border-top: 1px solid #dee2e5;
  border: 1px solid #dee2e5;
  color: #212529;
  > div {
    border-right: 1px solid #dee2e5;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer {
  grid-template-columns: 100px 60px repeat(10, 120px) 180px;
  border: 1px solid #dee2e5;
  color: #212529;

  > div {
    border-right: 1px solid #dee2e5;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer2 {
  grid-template-columns: 100px 1440px;
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
.search-block {
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 2px 2px 0 0;
  width: 99.3%;
  margin: 0 auto 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
}
.input-group-text {
  background: #0379fd;
  color: #fff;
}
.price-content {
  text-align: right;
  padding-right: 6.5px;
}
</style>
